import { useState, useContext, useEffect } from "react";
import { SECTIONS } from "../util/product-sections";
import FaceIcon from "./tryon/FaceIcon";
import LangContext from "../context/LangContext";
const SectionControl = ({
  currentSection,
  updateCurrentSectionState,
  currentPreset,
}) => {
  //console.log(currentPreset);
  const [isActive, setIsActive] = useState(false);
  const { t, changeLanguage } = useContext(LangContext);
  const [isPresetSelected, setIsPresetSelected] = useState();
//console.log(currentSection,currentPreset)
  useEffect(() => {
    if (currentPreset) {
      setIsPresetSelected(true);
    }
  }, []);
  function handleSelect(index) {
    if (SECTIONS[index] === currentSection) {
      setIsActive(!isActive);
      return;
    }
    if (!isActive && !currentSection) {
      setIsActive(true);
      return;
    }
    if (isActive) {
      updateCurrentSectionState(index);
      setIsActive(false);
    }
    if (!isActive) {
      setIsActive(true);
      updateCurrentSectionState(index);
      return;
    }
  }
  return (
    <>
      <section
        id="section-control"
        style={{
          flexDirection: currentSection ? "column" : "row",
          left: currentSection ? "-10px" : "0",
        }}
      >
        {SECTIONS.map((oneSection, idx) => {
          const imgURL =
            oneSection === currentSection
              ? oneSection + "-selected"
              : oneSection;
          const classes =
            !isActive && currentSection
              ? currentSection !== oneSection
                ? idx !== 0
                  ? "mobile-hidden"
                  : currentSection && "mobile-hidden"
                : null
              : currentSection === oneSection && "order-mobile-1";

          let text = t("tryOn." + oneSection);
          return (
            <div
              key={t("tryOn." + oneSection)}
              className={`img-container col ${classes} ${
                !currentSection && "noMinWidth"
              } `}
              style={{ border: !currentSection && "0" }}
            >
              {currentSection === oneSection ? (
                <div
                  id="icon-wrapper"
                  style={{
                    position: "absolute",
                    bottom: "-15px",
                    transform: "rotate(180deg)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    >
                      <path d="m5 9l7-7l7 7m-7 7V2" />
                      <circle cx="12" cy="21" r="1" />
                    </g>
                  </svg>
                </div>
              ) : null}
              {oneSection !== "face" ? (
                <img
                  src={`./images/${imgURL}.png`}
                  alt={oneSection}
                  style={{
                    marginTop: idx === 0 && "12px",
                  }}
                  onClick={() => handleSelect(idx)}
                />
              ) : (
                <FaceIcon
                  isSelected={currentSection === oneSection}
                  onclick={() => handleSelect(idx)}
                />
              )}
              <h3
                style={{
                  color: currentSection === oneSection ? "#BE5499" : null,
                }}
              >
                {text}
              </h3>
            </div>
          );
        })}
      </section>
    </>
  );
};
export default SectionControl;
