export function setAgulio(){
  const scriptSrc = '../js/auglioScript.js';

  // Controlla se lo script è già presente nel DOM
  const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);

  if (!existingScript) {
    // Lo script non è presente, quindi crealo e aggiungilo al DOM
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.defer = true;

    // Aggiungi lo script direttamente prima della chiusura del tag body
    document.body.appendChild(script);
  }
}
// File removeAgulio.jsx

 export function removeAgulio() {
  const scriptSrc = '../js/auglioScript.js';

  // Trova lo script con lo stesso src
  const scriptToRemove = document.querySelector(`script[src="${scriptSrc}"]`);

  if (scriptToRemove) {
    // Rimuovi lo script se trovato
    scriptToRemove.parentNode.removeChild(scriptToRemove);
  }
}


export function addEventListenerToProducts(){


  const scriptSrc = '../js/eventListenerProducts.js';

  // Controlla se lo script è già presente nel DOM
  const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);

  if (!existingScript) {
    // Lo script non è presente, quindi crealo e aggiungilo al DOM
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.defer = true;

    // Aggiungi lo script direttamente prima della chiusura del tag body
    document.body.appendChild(script);
  }

}

