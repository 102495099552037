import { useEffect } from "react";
function PresetAguilioBnt({  dataItem, isAgulioStarted }) {
  useEffect(() => {
      window.addEventListener("message", (e) => {
        if (typeof e.data === "string") {
          if (e.data === "iframeLoaded") {
  
            document.querySelectorAll(".auglio-tryon-btn").forEach((el) => {
              el.click();
            });
          }
        }
      });
    
  }, []);
  return (
    <div className="auglio-button absolute">
      { dataItem &&
          dataItem.map((oneData) => {
            return (
              <button
                key={oneData}
                className="auglio-tryon-btn"
                style={{ display: "none" }}
                data-item_id={oneData}
              ></button>
            );
          })
      
          }
    </div>
  );
}

export default PresetAguilioBnt;
