import "../index.css";
import { useContext } from "react";
import LangContext from "../context/LangContext";
import PageHeading from "../components/PageHeading";
import ChangeLanguage from "../components/ChangeLanguage";
import { Outlet, Link } from "react-router-dom";
import { useEffect } from "react";
function Landing({ setPage }) {
  const { t, changeLanguage } = useContext(LangContext);
  useEffect(() => {
    localStorage.removeItem("currentPresetTryOn");
    localStorage.removeItem("currentProduct");
    localStorage.removeItem("tryOnUSer");
    localStorage.removeItem("ProductDataItemID");
    localStorage.removeItem("currentCase");
  }, []);
  return (
    <main
      id="landing"
      className="relative w-full h-screen max-h-[605px] sm:max-h-[300px] xl:max-h-[545px] bg-[#EBDABE]"
    >
      <div
        style={{ zIndex: "1111" }}
        className="absolute z-11111 flex content-center justify-center py-6 px-5 bg-transparent"
      >
        <ChangeLanguage t={t} onChange={changeLanguage} />
      </div>
      {/*mobile hero image section*/}
      <section className="w-full h-full  max-h-[350px]  xl:max-h-[600px] sm:hidden relative">
        <div className="w-full h-full bg-[#020202] absolute inset-0"></div>
        <img
          className=" h-full bg-cover bg-right absolute inset-0 object-top object-cover"
          src="./images/hero/hero-img.jpg"
          style={{
            zIndex: "111",
          }}
        ></img>
      </section>
      <div className="mobile-hero-img bg-transparent relative w-full h-full  xl:max-h-[650px] flex">
        <img
          className="md:w-4/6 w-5/6 xl:w-3/6 mb-1 top-0 absolute right-20 h-full bg-contain object-cover  bg-right hidden sm:block object-top"
          src={"./images/hero/hero-img.jpg"}
          style={{
            zIndex: "11",
          }}
        ></img>
        <div className="w-full h-full bg-[#020202] absolute"></div>
        <section className="overlay-slide absolute inset-0 flex items-center justify-center">
          <div className="text-white text-left px-2 pe-12 xl:px-32 ">
            <article>
              <h2 className="xl:text-4xl w-3/4 md:w-full font-bold md:mb-4 mt-5">
                {t("landing.title")}
              </h2>
              <p className="xl:text-lg text-xs lg:text-lg m-0">
                {t("landing.desc")}
              </p>
            </article>
            <ul className="control-btn  mt-3  xl:mt-6 flex-col md:flex xl:flex-col items-start gap-2 md:gap-5">
              {" "}
              <li>
                <Link to="/preset">
                  <button>PRESET TRY-ON</button>
                </Link>
              </li>
              <li>{t("landing.presetBTN")}</li>
              <li>
                <Link to="/tryon">
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 17.5q1.875 0 3.188-1.312T16.5 13q0-1.875-1.312-3.187T12 8.5q-1.875 0-3.187 1.313T7.5 13q0 1.875 1.313 3.188T12 17.5m0-2q-1.05 0-1.775-.725T9.5 13q0-1.05.725-1.775T12 10.5q1.05 0 1.775.725T14.5 13q0 1.05-.725 1.775T12 15.5M4 21q-.825 0-1.412-.587T2 19V7q0-.825.588-1.412T4 5h3.15L8.4 3.65q.275-.3.663-.475T9.875 3h4.25q.425 0 .813.175t.662.475L16.85 5H20q.825 0 1.413.588T22 7v12q0 .825-.587 1.413T20 21zm0-2h16V7h-4.05l-1.825-2h-4.25L8.05 7H4zm8-6"
                      ></path>
                    </svg>
                    FREE TRY-ON
                  </button>
                </Link>
              </li>
              <li className="w-3/4 md:w-full">{t("landing.freeBTN")}</li>
            </ul>
          </div>
        </section>
      </div>
    </main>
  );
}
export default Landing;
