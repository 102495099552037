// src/i18n.js
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from './locales/en.json';
import it from './locales/it.json';

const initialLanguage = localStorage.getItem("language") || 'it'

const resources = {
    en: {translation: en},
    it: {translation: it},
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: initialLanguage,
        fallbackLng: initialLanguage,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
