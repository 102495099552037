//import PageHeading from "../components/PageHeading"; // Assuming you have a PageHeading component
import { useState } from "react";
import "../css/preset.css";
import PageHeading from "../components/PageHeading";
import preset from "../../src/util/preset.json";
import { useContext } from "react";
import LangContext from "../context/LangContext";
import OneMood from "../components/preset/OneMood";
import ModalPreset from "../components/preset/ModalPreset";
function Preset({ setPage }) {
  const [currentPreset, setCurrentPreset] = useState();
  const { t } = useContext(LangContext);
  function handleSelectPreset(selectedPreset) {
    if (
      currentPreset &&
      selectedPreset.presetName === currentPreset.presetName
    ) {
      setCurrentPreset();
      return;
    }

    setCurrentPreset(selectedPreset);
  }

  function onCloseModal() {
    setCurrentPreset();
  }
  return (
    <main id="preset">
      <div className="flex content-center justify-center py-6 px-5">
        <PageHeading setPage={setPage} />
      </div>
      <section id="preset-bg">
        <div className="w-full h-full relative"></div>
      </section>
      <section className="px-2 md:px-16">
        <section className="relative">
          <div>
            <h2 id="preset-h2"> {t("preset.title")}</h2>
            <div className=" mood-collage flex justify-end  gap-4 md:gap-3 text-xs sm:text-sm md:text-sm sm:flex-row">
              {preset.map((oneP, idx) => {
                return (
                  <OneMood
                    key={oneP.presetName}
                    number={idx + 1}
                    onePreset={oneP}
                    currentPreset={currentPreset}
                    onSelect={handleSelectPreset}
                    t={t}
                    setPage={setPage}
                  ></OneMood>
                );
              })}
            </div>
          </div>
        </section>
        <article id="preset-lorem" className="text-center mt-6 mb-12">
          {t("preset.mainText")}
        </article>
      </section>

      <ModalPreset onClose={onCloseModal} currentPreset={currentPreset} />
    </main>
  );
}

export default Preset;
