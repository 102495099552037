import { useState } from "react";
import Preset from "../pages/Preset";
import PresetTryOn from "./tryon/PresetTryOn";
import ChevronMenu from "./button/ChevronMenu";
const ProductsMobile = ({
  products,
  productSelected,
  onSelectProduct,
  currentPreset,
  currentSection,
  updateCurrentPreset,
  updateDataItems,
  updateCurrentCase,
  currentCase,
}) => {
  const [isActive, setIsActive] = useState(false);
  function handleSelect(idx) {
    onSelectProduct(idx);
    setIsActive(false);
  }
  return (
    <div
      id="products-mobile"
      className={`${currentSection === "preset" && "preset-t-mobile"}`}
    >
      {currentSection !== "preset" ? (
        <>
          <header
            style={{ position: "relative", paddingBottom: "3px" }}
            className="text-bold"
            onClick={() => setIsActive(!isActive)}
          >
            {productSelected
              ? productSelected.productName
              : products[0] && products[0].productName}
            <ChevronMenu isActive={isActive} />
          </header>
          {isActive && (
            <main className="flex" id="products-mobile-menu">
              <ul>
                {products.map((oneP, productIndexMap) => {
                  if (!oneP.section) {
                    return;
                  }
                  return (
                    <li
                      key={oneP.productName}
                      onClick={() => handleSelect(productIndexMap)}
                    >
                      {oneP.productName}
                    </li>
                  );
                })}
              </ul>
            </main>
          )}
        </>
      ) : (
        <PresetTryOn
          updateCurrentPreset={updateCurrentPreset}
          currentSection={currentSection}
          updateDataItems={updateDataItems}
          updateCurrentCase={updateCurrentCase}
          currentPreset={currentPreset}
          currentCase={currentCase}
        />
      )}
    </div>
  );
};
export default ProductsMobile;
