const ResetButton = ({onReset}) => {
  return (
    <button className="flex gap-2 content-center" onClick={() => onReset()} >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 24 24"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          d="M20 8a8.955 8.955 0 0 0-8.036-5C7.014 3 3 7.03 3 12m1 4a8.955 8.955 0 0 0 8.036 5C16.986 21 21 16.97 21 12M9 16H3v6M21 2v6h-6"
        />
      </svg>
      <span className = "uppercase text-bold text-sm my-auto">reset</span>
    </button>
  );
};
export default ResetButton
