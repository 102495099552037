import {createContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const LangContext = createContext();
export const LangProvider = ({children}) => {
    let initialLanguage = localStorage.getItem("language");
    console.log(initialLanguage, "initialLanguage")
    const {t, i18n} = useTranslation();

    useEffect(() => {
        // Ottieni l'URL corrente
        const url = window.location.href;
        let parts;
        // Dividi l'URL utilizzando il carattere "=" come separatore
        if (url.indexOf("=") !== -1) {
            parts = url.split("=");
            initialLanguage = parts[parts.length - 1];
        }
        i18n.changeLanguage(initialLanguage);
    }, []);

    const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

    function changeLanguage(lng) {
        i18n.changeLanguage(lng);
        setCurrentLanguage(lng)
        console.log(lng)
        localStorage.setItem("language", lng);
    }

    return (
        <LangContext.Provider value={{currentLanguage, t, changeLanguage}}>
            {children}
        </LangContext.Provider>
    );
};
export default LangContext;
