import { createPortal } from "react-dom";
import preset from "../../util/preset.json";
import { useEffect, useState } from "react";
import { CameraLoader } from "../tryon/CameraLoader";
function ModalPreset({ currentPreset, onClose, tryonImage }) {
  const [currentPresetImageN, setCurrentPresetImageN] = useState();
  useEffect(() => {
    if (currentPreset) {
      preset.forEach((oneP, idx) => {
        if (oneP.presetName === currentPreset.presetName) {
          setCurrentPresetImageN(idx + 1);
        }
      });
    }
  }, [currentPreset]);
  function handleClose() {
    setTimeout(() => {
      setCurrentPresetImageN();
    }, 500);

    onClose();
  }
  let sourceImg = "./images/preset/preset-" + currentPresetImageN + ".jpg";

if(tryonImage){


  sourceImg =  "./images/presetImages/zoom/" + tryonImage
}

  return (
    <div id="modal-preset" className={currentPreset ? "fadeIn" : "fadeOut"}>
      <div className="content relative">
        <div className="absolute" style={{ left: "50%", top: "50%" }}>
          <CameraLoader />
        </div>
        <button onClick={handleClose} className="close-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-x"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
          </svg>
        </button>
        <div className="w-full h-full relative ">
          <img src={sourceImg}></img>
        </div>
      </div>
    </div>
  )
}
export default ModalPreset;
