import { TryonButton } from "../button/TryOnButton";
import ResetButton from "../button/ResetButton";
import SplitButton from "../button/SplitButton";
import { useEffect, useRef, useState } from "react";
import { CameraLoader } from "./CameraLoader";
import { Mirror } from "./Mirror";
import { type } from "@testing-library/user-event/dist/type";
import ZoomControl from "./ZoomControl";

const PhotoContainer = ({
  productSelected,
  dataItemId,
  onReset,
  currentSection,
  currentPreset,
}) => {
  const isDataItemsaObject = typeof dataItemId === "object";
  const [isLoaded, setIsLoaded] =
    useState(localStorage.getItem("isAgulioLoaded")) || undefined;

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (typeof e.data === "string") {
        if (e.data === "iframeLoaded") {
          localStorage.setItem("isAgulioLoaded", "true");
          const liveCam = document.getElementById("liveCamContainerIn");
          setIsLoaded(true);
        }
      }
    });
  }, []);
  function handleCameraReset() {
    localStorage.removeItem("isAgulioLoaded");
    onReset();
  }
  return (
    <section className="camera-wrapper">
      <div className="camera">
       {isLoaded && <ZoomControl isLoaded={isLoaded} />}
        <div id="reset-btn">
          <ResetButton onReset={handleCameraReset} />
        </div>
        <Mirror
          isPressed={isLoaded}
          dataItem={dataItemId}
          isPreset={
            currentSection === "preset" &&
            currentPreset &&
            currentPreset.presetName !== undefined &&
            isDataItemsaObject
          }
        />
        <div className="photo-background"></div>
        <div className="coffe " style={{ zIndex: "111" }}>
          <div className="border-l-2 border-t-2 border-white absolute inset-y-5 h-24 w-11 inset-x-5"></div>
          <div className="border-r-2 border-b-2 border-white absolute bottom-5 h-24 w-11 right-5"></div>
          {productSelected !== undefined ? <CameraLoader /> : null}
        </div>
      </div>
    </section>
  );
};
export default PhotoContainer;
