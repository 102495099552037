import { createContext, useState } from "react";
import { SECTIONS } from "../util/product-sections";
const SectionContext = createContext();
export const initialSection = localStorage.getItem("currentSection") || SECTIONS[0];
export const SectionProvider = ({ children }) => {

  const [currentSection, setCurrentSection] = useState(initialSection);

  return (
    <SectionContext.Provider value={{ currentSection, setCurrentSection }}>
      {children}
    </SectionContext.Provider>
  );
};
export default SectionContext;
