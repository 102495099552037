import { createContext, useState } from "react";
import preset from "../util/preset.json";
const LookContext = createContext();
const cases = preset[0].items;
let initialLook = localStorage.getItem("currentCase") || cases[0];


export const LookProvider = ({ children }) => {
  const [currentLook, setCurrentLook] = useState(initialLook);
  return (
    <LookContext.Provider value={{ currentLook, setCurrentLook }}>
      {children}
    </LookContext.Provider>
  );
};
export default LookContext;
