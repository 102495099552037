import CartBtn from "../button/CartBtn";
import products from "../../util/products.json";
import {useContext} from "react";
import LangContext from "../../context/LangContext";

export const Checkout = ({
                             product,
                             currentPreset,
                             currentCase,
                             currentSection,
                             dataItemIds,
                         }) => {
    const languages = {
        en: "ENG",
        it: "ITA",
    };
    let {t, currentLanguage} = useContext(LangContext);
    if (!currentLanguage) {
        console.log('language is undefined')
        currentLanguage = 'en'
    }
    const key = `ecommerceLink${languages[currentLanguage]}`;

    const eccomerceLink = product && key && product[key];
    let presetTotalCost = 0;
    //console.log(dataItemIds);
    let totalProducts =
        dataItemIds &&
        products.filter((oneP) => {
            return dataItemIds.indexOf(oneP.id.toString()) !== -1;
        });
    //console.log(totalProducts)
    let p = product;
    return (
        <section
            style={{zIndex: 11111, paddingBottom: "80px"}}
            id="checkout"
        >
            {currentSection !== "preset" ? (
                p && (
                    <>
                        <ul className="flex flex-col gap-2 md:mx-0 lg:me-auto ">
                            <li
                                key={p.sku ? p.sku : p.code}
                                className="flex justify-start content-center gap-4 "
                            >
                                <div className="my-auto mx-auto w-full">
                  <span className="my-auto mx-auto text-bold w-full">
                    {p.productName}
                  </span>
                                    <p>
                                        {t("cost")}:{" "}
                                        <span className="text-bold">
                      {p.cost ? p.cost : "17.90"} €
                    </span>
                                    </p>
                                </div>
                                <CartBtn link={eccomerceLink}/>
                            </li>
                        </ul>
                    </>
                )
            ) : (
                <ul className="flex flex-col gap-2 md:mx-0 lg:me-auto ">
                    {currentPreset &&
                        currentPreset[currentCase].map((p) => {
                            const key = `ecommerceLink${languages[currentLanguage]}`;
                            const ecLink = p[key];
                            const productFound = products.find((prod) => prod.id === p.id);
                            presetTotalCost += +productFound.cost;
                            return (
                                <li
                                    key={p.sku ? p.sku : p.code}
                                    className="flex justify-start content-center gap-1 "
                                >
                                    <div className="my-auto mx-auto w-full">
                    <span className="my-auto mx-auto text-bold w-full">
                      {p.productName}
                    </span>
                                        <p>
                                            {t("cost")}:{" "}
                                            <span className="text-bold">
                        {productFound.cost ? productFound.cost : "17.90"} €
                      </span>
                                        </p>
                                    </div>
                                    <CartBtn t={t} link={ecLink}/>
                                </li>
                            );
                        })}
                </ul>
            )}
        </section>
    );
};
