import "./App.css";
import Landing from "./pages/Landing";
import Preset from "./pages/Preset";
import Tryon from "./pages/Tryon";
import "./tailwind.css";
import {useState, useEffect} from "react";
import ChangeLanguage from "./components/ChangeLanguage";
import i18n from "./i18n"; // Importa il tuo file i18n
import {LangProvider} from "./context/LangContext";
import {CategoryProvider} from "./context/PresetContext";
import {SectionProvider} from "./context/SectionCtx";
import {LookProvider} from "./context/LookCtx";
import {useParams} from "react-router-dom"; // supponendo che stai usando React Router

import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route} from "react-router-dom";

function App() {
    const [currentPage, setCurrentPage] = useState(
        localStorage.getItem("currentPageApp") || undefined
    );

    // Effetto useEffect per gestire il salvataggio e il ripristino dello stato
    useEffect(() => {
        // Salva il valore dello stato in localStorage
        localStorage.setItem("currentPageApp", currentPage);

        if (
            currentPage === "Default" ||
            currentPage == "undefined" ||
            currentPage === undefined
        ) {
            document.getElementById("root").style.borderBottom = "0";
        } else {
            document.getElementById("root").style.borderBottom = "7px solid  #ebdabe";
        }
    }, [currentPage]);


    return (
        <SectionProvider>
            <CategoryProvider>
                <LookProvider>
                    <LangProvider>
                        <Routes>
                            <Route path="/" element={<Landing/>}/>
                            <Route path="/preset" element={<Preset/>}/>
                            <Route path="/tryon" element={<Tryon/>}/>
                        </Routes>
                    </LangProvider>
                </LookProvider>
            </CategoryProvider>
        </SectionProvider>
    );
}

export default App;
