import { useEffect, useState } from "react";
const ZoomControl = ({ isLoaded }) => {
  //liveCamContainer id of the cameralive
  const [zoom, setZoom] = useState(1);

  function zoomIn() {
    if (zoom === 2.2) {
      return;
    }
    const newZoomValue = parseFloat((zoom + 0.2).toFixed(1));
    setZoom(newZoomValue);
  }
  function zoomOut() {
    if (zoom === 1) {
      return;
    }
    const newZoomValue = parseFloat((zoom - 0.2).toFixed(1));
    setZoom(newZoomValue);
  }

  useEffect(() => {
    if (isLoaded) {
      const auglioMirror = document.getElementById("auglio-mirror");
      if (!auglioMirror) {
        return;
      }
      auglioMirror.style.transform = "scale(" + zoom + ")";
    }
  }, [zoom]);

  return (
    <aside
      style={{
        justifyContent: "flex-start",
        alignContent: "flex-end",
        zIndex: "1111111111111111111",
        height: "0",
      }}
      className="absolute flex flex-col end-0 top-0  px-5 py-4  gap-2 h-20 text-[#3D3B40]"
    >
      <button 
      className="ms-auto cursor-pointer text-white" onClick={zoomIn}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-zoom-in"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
          <path d="M7 10l6 0" />
          <path d="M10 7l0 6" />
          <path d="M21 21l-6 -6" />
        </svg>
      </button>
      <button 

      className="ms-auto cursor-pointer z-50 text-white" onClick={zoomOut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-zoom-out"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
          <path d="M7 10l6 0" />
          <path d="M21 21l-6 -6" />
        </svg>
      </button>
    </aside>
  );
};

export default ZoomControl;
