import { createContext, useState } from "react";

const PreContext = createContext();
let initialPreset;
try {
  initialPreset =
    JSON.parse(localStorage.getItem("currentPresetTryOn")) || undefined;
} catch (error) {
  console.error("Error parsing JSON:", error);
  // Gestisci l'errore, ad esempio, assegna un valore predefinito.
  initialPreset = "undefined";
}
export const CategoryProvider = ({ children }) => {

  const [currentPreset, setCurrentPreset] = useState(initialPreset);
  return (
    <PreContext.Provider value={{ currentPreset, setCurrentPreset }}>
      {children}
    </PreContext.Provider>
  );
};
export default PreContext;
