import { CameraLoader } from "../tryon/CameraLoader";
import PreContext from "../../context/PresetContext";
import { useContext } from "react";
import SectionContext from "../../context/SectionCtx";
import LookContext from "../../context/LookCtx";
import { Link } from "react-router-dom";

function OneMood({ number, onePreset, currentPreset, onSelect, t, setPage }) {
  const sourceMoodImage = "./images/preset/preset-" + number + "-cutted.jpg";
  const presetName = t(onePreset.presetName);
  const { currentLook, setCurrentLook } = useContext(LookContext);
  const { currentGlobalPreset, setCurrentPreset } = useContext(PreContext);
  const { currentSection, setCurrentSection } = useContext(SectionContext);

  function handleSelectPreset(onePreset) {
    const newPreset = onePreset;
    const presetString = JSON.stringify(newPreset);
    localStorage.setItem("currentPresetTryOn", presetString);
    onSelect(onePreset);



  }
  function handleClickButton(newPreset) {



    setCurrentLook("brunette/mulatto");
    setCurrentSection("preset");
    setCurrentPreset(newPreset);
  }
  return (
    <div className="oneMood-container control-btn">
      <Link to = "/tryon" className="oneMood relative">
        <div
          className="absolute -z-30"
          style={{ left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}
        >
          <CameraLoader />
        </div>
        <img
          onClick={() => handleClickButton(onePreset)}
          src={sourceMoodImage}
          loading="lazy"
        ></img>
      </Link>
      <Link to="/tryon">
        <button
          className={`uppercase ${
            onePreset &&
            currentPreset &&
            presetName === currentPreset.presetName &&
            "md:block btn-selected"
          } `}
          key={onePreset.presetName}
          onClick={() => handleClickButton(onePreset)}
        >
          <span className="w-100 block">{presetName}</span>
          <span>{t("tryme")}</span>
        </button>
      </Link>
    </div>
  );
}
export default OneMood;
