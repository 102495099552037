import LangContext from "../context/LangContext";
import { useContext } from "react";
const SectionTitle = ({ currentSection }) => {
  const { t } = useContext(LangContext);
  return (
    <section>
      <h2
        id="section-title"
        className="uppercase my-10 text-3xl text-bold section-title "
      >
        virtual try-on {currentSection}
      </h2>
      {currentSection && (
        <section className="mobile-section-current ">
          <img
            src={`./images/${currentSection}-selected.png`}
            style={{ transform: "scale(0.6)", objectFit: "cover" }}
          ></img>
        </section>
      )}

      <section
        id="istruction"
        style={{ paddingTop: currentSection === "preset" && "25px" }}
      >
        <div id="instruction-divider"></div>

        {currentSection === "preset" ? (
          <div
            style={{ marginTop: "5px" }}
            dangerouslySetInnerHTML={{ __html: t("instruction.preset") }}
          />
        ) : (
          <div
            style={{ marginTop: "20px" }}
            dangerouslySetInnerHTML={{ __html: t("instruction.free") }}
          />
        )}
      </section>
    </section>
  );
};
export default SectionTitle;
