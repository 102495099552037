import ChangeLanguage from "./ChangeLanguage";
import { BackButton } from "./button/BackButton";
import { useContext } from "react";
import LangContext from "../context/LangContext";
import { Link } from "react-router-dom";
function PageHeading({
  onBack = null,
  setPage,
  currentSection,
  productSelected,
}) {
  const { t, changeLanguage } = useContext(LangContext);
  const backClasses = "flex gap-1 content-center text-bold";
  let pagePath = [];
  pagePath = [currentSection];
  if (productSelected) {
    pagePath = [currentSection, productSelected.productName];
  }
  function goHome() {
    window.location.href = "/";
  }
  return (
    <div
      id="page-heading"
      className="flex justify-between w-full lg:pe-7 px-2 lg:px-0 "
    >
      <span className="flex">
        <span>Virtual AR</span>
        {pagePath &&
          pagePath[0] !== undefined &&
          pagePath.map((onePath, pathIdx) => {
            if (onePath === undefined || onePath === null) {
              return;
            }
            if (currentSection === "preset" && pathIdx === 1) {
              return;
            }
            const sectionTxt =
              pathIdx === 0 && onePath ? t("tryOn." + onePath) : null;

            return (
              <span key={pathIdx} className=" flex">
                <svg
                  width={22}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12.6 12L8.7 8.1q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.6 4.6q.15.15.213.325t.062.375q0 .2-.062.375t-.213.325l-4.6 4.6q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7z"
                  />
                </svg>
                <span
                  style={{
                    textAlign: "center",
                  }}
                >
                  {pathIdx === 1
                    ? onePath[0] +
                      onePath.substring(1, onePath.length).toLowerCase()
                    : sectionTxt}
                </span>
              </span>
            );
          })}
      </span>
      <div className="flex gap-2 md:gap-4 content-center">
        <button
          style={{ zIndex: 1111111 }}
          className={backClasses}
          onClick={() => {currentSection === "preset" ? onBack() : goHome()}}
        >
          <BackButton />
        </button>
        <Link to="/">
          <button className="flex gap-1 content-center" id="home-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-home"
              width="22"
              height="22 "
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
              <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
              <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
            </svg>
          </button>
        </Link>
        <ChangeLanguage onChange={changeLanguage} t={t} />
      </div>
    </div>
  );
}
export default PageHeading;
