import {useEffect, useState, useContext} from "react";
import LangContext from "../../context/LangContext";
import presetMenu from "../../util/preset.json";
import ChevronMenu from "../button/ChevronMenu";
import preset from "../../util/preset.json";
import "../../css/tryon/preset-try-on.css";

function PresetTryOn({
                         updateDataItems,
                         updateCurrentPreset,
                         currentPreset,
                         currentCase,
                         updateCurrentCase,
                     }) {
    const cases = preset[0].items;
    const {t, changeLanguage} = useContext(LangContext);
    useEffect(() => {
        if (currentPreset) {
            window.addEventListener("message", (e) => {
                if (typeof e.data === "string") {
                    if (e.data === "iframeLoaded") {
                        document.querySelectorAll(".auglio-tryon-btn").forEach((el) => {
                            el.click();
                        });
                    }
                }
            });
        }
    }, []);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const [presetMenuBack, setPresetMenuBack] = useState({
        width: null,
        left: null,
        opacity: 0,
    });

    const [catMenuBack, setCatMenuBack] = useState({
        width: null,
        left: null,
        opacity: 0,
    });

    function handleSelectCase(caseIndex) {
        updateCurrentCase(cases[caseIndex]);
        localStorage.setItem("currentCase", cases[caseIndex]);
        setCurrentCaseHover(caseIndex)
    }

    function handleSelectPreset(presetIdx) {
        const newPreset = presetMenu[presetIdx];
        const presetString = JSON.stringify(newPreset);
        if (currentPreset && newPreset.presetName === currentPreset.presetName) {
            updateCurrentPreset();
            localStorage.removeItem("currentPresetTryOn");
            // window.location.reload();
            return;
        }
        localStorage.setItem("currentPresetTryOn", presetString);
        let dataItemsPreset =
            currentCase &&
            newPreset[currentCase].map((onePreset) => {
                return onePreset.id;
            });

        updateCurrentPreset(preset[presetIdx]);
        updateDataItems(dataItemsPreset);
        localStorage.setItem("ProductDataItemID", dataItemsPreset);
        setCurrentCaseHover(false)
        setIsMenuCasesActive(!isMenuActiveCases);
        console.log(localStorage.getItem('language'))

        window.location.reload();

    }

    const [isMenuActiveCases, setIsMenuCasesActive] = useState(
        windowSize.width < 1024 ? false : false
    );
    const [currentCaseHover, setCurrentCaseHover] = useState(false);


    function toggleMenu() {
        if (isMenuActiveCases === true) {
            setCurrentCaseHover(false)
        }
        setIsMenuCasesActive(!isMenuActiveCases);
    }

    return (
        <section id="preset-tryon">
            <h3>{t("category")}: </h3>
            <section id="preset-cases">
                <button
                    onClick={toggleMenu}
                    className={`oneCase 
                  `}
                >
                    {t(currentCase)} <br/>
                    <small>{t(currentPreset?.presetName)}</small>

                    {<ChevronMenu isActive={isMenuActiveCases}/>}
                </button>

                {cases.map((oneCase, caseIndex) => {
                    const text = t && t(oneCase);
                    const menu = "PRESET";
                    return (
                        <button
                            style={{zIndex: 1}}
                            key={caseIndex}
                            onClick={() => handleSelectCase(caseIndex)}
                            className={`oneCase  ${
                                !isMenuActiveCases && currentCase && "mobile-hidden"
                            }
              ${currentCase === oneCase && "preset-selected"}
              `}
                        >
                            {text}
                        </button>
                    );
                })}
                <div
                    id="preset-menu-back"
                    style={{
                        width: presetMenuBack.width,
                        left: presetMenuBack.left,
                        opacity: presetMenuBack.opacity,
                    }}
                ></div>
                <section
                    id="presets-mobile"
                    style={{
                        marginTop: `${(currentCaseHover + 1) * 31}px`,
                        zIndex: !isMenuActiveCases && "-1111111",
                    }}
                    className={currentCaseHover === false ? "mobile-hidden shadow-mobile-hidden" : null}
                >
                    {preset.map((oneP, idx) => {
                        const text = t && t(oneP.presetName);
                        return (
                            <button
                                style={{
                                    borderBottom: "0",
                                    borderRadius: "none"

                                }}
                                key={idx}
                                className={`oneCase onePreset ${
                                    currentPreset &&
                                    currentPreset.presetName === oneP.presetName &&
                                    "preset-selected"
                                }
              ${!isMenuActiveCases && "mobile-hidden"}
              `}
                                onClick={() => handleSelectPreset(idx)}
                            >
                                {text}
                            </button>
                        );
                    })}
                </section>
            </section>
            <h3>{t("presetMenu")}: </h3>
            <section
                id="presets"
                style={{paddingTop: `${(currentCaseHover + 1) * 43}px`}}
                className={currentCaseHover === false ? "mobile-hidden" : "py-3"}
            >
                {preset.map((oneP, idx) => {
                    const text = t && t(oneP.presetName);
                    const menu = "CATEGORY";

                    return (
                        <button
                            key={idx}
                            className={`oneCase onePreset  ${
                                currentPreset &&
                                currentPreset.presetName === oneP.presetName &&
                                "preset-selected"
                            }
              ${!currentCase && "mobile-hidden"}
              `}
                            onClick={() => handleSelectPreset(idx)}
                            style={{borderBottom: idx === 3 && "0", transition: "none"}}
                        >
                            {text}
                        </button>
                    );
                })}
                <div
                    style={{
                        width: catMenuBack.width,
                        left: catMenuBack.left,
                        opacity: catMenuBack.opacity,
                    }}
                    id="category-menu-back"
                ></div>
            </section>
        </section>
    )
        ;
}

export default PresetTryOn;
