import { useContext } from "react";
import LangContext from "../../context/LangContext";
const CartBtn = ({ link }) => {
  const { t } = useContext(LangContext);
  return (
  
      <a
        target="_blank"
        href={link && link}
        className="m-0 bg-purple-r py-3 px-3 flex justify-center content-center gap-1  text-white rounded-3xl"
        style={{ minWidth: "fit-content" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
          />
        </svg>
        <span className="uppercase text-nowrap w-full flex my-auto">
          {t("buyNow")}
        </span>
      </a>
    
  );
};

export default CartBtn;
