export const LoremIpsum = () => {

return(
  <p className="lorem mt-6 md:mt-24 w-full ">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
  rhoncus viverra orci id porta. Nulla consequat cursus risus a
  pretium. Proin venenatis luctus sapien et ornare. Praesent
  luctus scelerisque libero, vitae pellentesque risus placerat
  nec. Aliquam a pulvinar nisi, vitae sollicitudin dolor.
</p>

)


}