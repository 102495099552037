import { useState } from "react";
import ModalPreset from "../preset/ModalPreset";

function PresetImage({ currentCase, currentPreset }) {
  let presetName = currentPreset && currentPreset.presetName;
  let currentCaseImage = currentCase;
  const [currentPresetImageSelected, setCurrentPresetImageSelected] =
    useState(false);

  function handleSelectImage() {
    setCurrentPresetImageSelected(currentPreset);
  }

  function onCloseModal() {
    setCurrentPresetImageSelected();
  }

  if (currentPreset) {
    if (currentPreset.presetName === "night out") {
      presetName = "eventnight";
    }
    if (currentCaseImage === "brunette/mulatto") {
      currentCaseImage = "brunette";
    }
    if (currentCaseImage === "light-red") {
      currentCaseImage = "readhead";
    }
  }
  let fileName = currentCaseImage + "_" + presetName + ".JPG"
  const imageUrlPath =
    "./images/presetImages/" + fileName;
//console.log(fileName)
  return (
    <aside id="preset-tryon-image">
      <div>
        {currentPreset ? (
          <img onClick={handleSelectImage} src={imageUrlPath}></img>
        ) : (
          <div></div>
        )}
      </div>
      <ModalPreset tryonImage={fileName} onClose={onCloseModal} currentPreset={currentPresetImageSelected} />

    </aside>
  );
}
export default PresetImage;
