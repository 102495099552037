import { useEffect } from "react";

function Products({ currentProducts, selectProduct, productSelected }) {
  return (
    <section id="products" className="mt-8">
      {currentProducts.map((oneProduct, productID) => {
        if (!oneProduct.section) {
          return;
        }
        const isSelected =
          productSelected &&
          oneProduct.productName === productSelected.productName;
        const productName =
          oneProduct.productName[0] +
          oneProduct.productName
            .substring(1, oneProduct.productName.length)
            .toLowerCase();

        return (
          <div
            key={productID}
            id={oneProduct.id}
            style={{ color: isSelected && "rgba(190, 84, 153, 1)" }}
            className="one-product flex content-center me-4"
            onClick={() => selectProduct(productID)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 15 15"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M2 7.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5"
                clipRule="evenodd"
              />
            </svg>
            <div className="flex content-center justify-center">
              {productName}
            </div>
          </div>
        );
      })}
    </section>
  );
}

export default Products;
