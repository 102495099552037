const ChevronMenu = ({ isActive }) => {
  return (
    <svg
      style={{
        transform: isActive && "rotate(180deg)",
        position: "absolute",
        right: "2px",
        top: "-5px",
      }}
      className="chevron-menu"
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6z"
      />
    </svg>
  );
};
export default ChevronMenu;
